import {isInViewport} from "./helpers";

global.$ = global.jQuery = require('jquery');

// Vendor
import Swiper, {Navigation, Autoplay, EffectCreative} from 'swiper';
import 'swiper/css';

import AOS from 'aos';

AOS.init({
    startEvent: 'load',
    offset: 100,
    duration: 600,
    easing: 'ease-in-sine',
    placement: 'top-bottom',
    delay: 300
});

window.Swiper = Swiper;
window.Navigation = Navigation;
window.Autoplay = Autoplay;
window.EffectCreative = EffectCreative;

import { Fancybox } from "@fancyapps/ui";
window.Fancybox = Fancybox;

// Components
import '../../../partials/to-top-button/to-top-button'
import '../../../partials/anchor/anchor'
import '../../../partials/header/header'
import '../../../partials/burger/burger'
import '../../../partials/mobile-menu/mobile-menu'
import '../../../partials/search-form/filter'
import '../../../partials/select/select'

// dynamically init on scroll
document.addEventListener('scroll', function () {
    if (isInViewport(document.querySelector('.js-slider-featured-listings'))) {
        import('../../../partials/slider/slider-featured-listings.js' /* webpackIgnore: true */)
    }
    if (isInViewport(document.querySelector('.js-video-embed'))) {
        import('../../../partials/video-embed/video-embed.js' /* webpackIgnore: true */)
    }
    if (isInViewport(document.querySelector('.js-input-password'))) {
        import('../../../partials/input/input-password.js' /* webpackIgnore: true */)
    }
    if (isInViewport(document.querySelector('.js-property-map'))) {
        import('../../../partials/google-map/google-map.js' /* webpackIgnore: true */)
    }
    if (isInViewport(document.querySelector('.js-lightbox-gallery'))) {
        import('../../../partials/gallery/gallery.js' /* webpackIgnore: true */)
    }
})

document.addEventListener('DOMContentLoaded', function () {
    if (isInViewport(document.querySelector('.js-slider-featured-listings'))) {
        import('../../../partials/slider/slider-featured-listings.js' /* webpackIgnore: true */)
    }
    if (isInViewport(document.querySelector('.js-video-embed'))) {
        import('../../../partials/video-embed/video-embed.js' /* webpackIgnore: true */)
    }
    if (isInViewport(document.querySelector('.js-input-password'))) {
        import('../../../partials/input/input-password.js' /* webpackIgnore: true */)
    }
    if (isInViewport(document.querySelector('.js-property-map'))) {
        import('../../../partials/google-map/google-map.js' /* webpackIgnore: true */)
    }
    if (isInViewport(document.querySelector('.js-lightbox-gallery'))) {
        import('../../../partials/gallery/gallery.js' /* webpackIgnore: true */)
    }
});
